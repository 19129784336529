<template>
    <div class="">
       <my-goods-contaains v-for="item in list" :key="item.id">
            <div class="content">
            <div class="left"  @click="itemclick(item.id)">
                        <img :src="item.path" alt="">
                </div>
                <div class="right">
                    <h5 class="currency">城市：{{item.city}}</h5>
                    <p class="housetype">位置：{{item.location}}</p> 
                    <div title="显示分享面板" style="margin-top: 8px;" @click="showshare(item)">分享</div>
                    <!-- f分享面板 -->
                  <van-share-sheet
                    v-model:show="showShare"
                    title="立即分享"
                    :options="options"
                     @select="onSelect" />
                </div>
            </div>
        </my-goods-contaains>
       <div class="mask"  @click="change" id="mask">
            <img src="../../../assets/img/town_recommend/shareto.png" alt="">
        </div>
    </div>
</template>

<script >
import {useRouter} from 'vue-router'
import { Toast } from 'vant';
import { ref, getCurrentInstance} from 'vue'
import {commomSharefr,commomShare} from '../../../utils/share'
import {useMainStore} from '../../../store/index'
export default {
    name: "My CateItem",
    
    setup() {
        const userIdPinia = useMainStore().userId;
        const route = useRouter()
        var itemdata =ref(Object)
        const showShare = ref(false);
        function showshare(item){
            itemdata=item;
            showShare.value = true
        }
        function change() {
            document.querySelector("#mask").setAttribute("style","display:none")
        }


          // 微信分享模板
        const options = [
            [
                { name: '微信', icon: 'wechat' },
                { name: '朋友圈', icon: 'wechat-moments' },
                // { name: '微博', icon: 'weibo' },
                // { name: 'QQ', icon: 'qq' },
            ],
            // [
            //     { name: '复制链接', icon: 'link' },
            //     { name: '分享海报', icon: 'poster' },
                // { name: '二维码', icon: 'qrcode' },
            //     { name: '小程序码', icon: 'weapp-qrcode' },
            // ],
        ];
        const onSelect = (option) => {
             if(option.name == "微信") {
                 const imgurll = location.href.split("#")[0];
                 const imgurlls = imgurll.substr(0,imgurll.length -1)  //https://app.51zxcc.com
                 const url = location.href.split("#")[0]  + '#' + '/detail/' + itemdata.id  + '?userId=' + userIdPinia
                 const imgurl = imgurlls + itemdata.path //图片url
                 const brand = "泓溪园区"
                commomSharefr(itemdata.city,url,imgurl,itemdata.location,brand);
                showShare.value = false;
                document.querySelector("#mask").setAttribute("style","display:block")
             }else if(option.name == "朋友圈") {
                const imgurll = location.href.split("#")[0];
                 const imgurlls = imgurll.substr(0,imgurll.length -1)  //https://app.51zxcc.com
                 const url = location.href.split("#")[0]  + '#' + '/detail/' + itemdata.id  + '?userId=' + userIdPinia
                 const imgurl = imgurlls + itemdata.path //图片url
                  const brand = "泓溪园区"
                commomShare(itemdata.city,url,imgurl,itemdata.location,brand);
                 showShare.value = false;
                 document.querySelector("#mask").setAttribute("style","display:block")
             }else if(option.name == "二维码") {
                  alert("二维码")
                  showShare.value = false;
             }else if(option.name == "QQ") {
                 alert("QQ")
                  showShare.value = false;
             }
            Toast(option.name);
            showShare.value = false;
        };
         // 当前登录的id，获取用户的ID
        const userId = window.localStorage.getItem("id")
        function itemclick(id) {
            route.push({
            path: `/catedetail/${id}`,
            query: {userId: userId}
        })
    }

        return {
            itemclick,onSelect,showshare,change,
            options,showShare,itemdata,route
        }
    },
    props: {
        list: {
            type: Array,
            default: []
        },     
    }
}
</script>

<style scoped>
.mask{
    /* display: none;
    height: 100%;
    width: 100%;
    position:absolute;
    z-index: 10;
    opacity: 0.8;
    top: 0;
    left: 0;
    background: #000; */
    display: none;
    color: #fff;
    position: fixed;
    left: 0px;
    right: 0;
    top: 0;
    z-index: 1;
    padding-top: 4px;
    height: 95%;
    background: #000;
    opacity: 0.7;
}
.mask img {
    width: 118px;
    height: 165px;
    float: right;
    margin-right: 56px;
    margin-top: 125px;
}
 .content {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    height: 150px;
    }
    .left {
         flex: 40%;
         width: 171px;
         margin: 10px;
         margin-right: 15px;
    }
  .left img {
        height: 100%;
        width: 100%;
        border: 0;
   }
.right {
        display: flex;
        flex-direction: column;
        flex: 50%;
        flex-wrap: nowrap;
        width: 133px;
        margin-top: 20px;
    }
   p {
        margin-bottom: 0;
        overflow: hidden;
  }
  h5 {
      color: red;
     font-size: 13px;
  }
  .content {
      display: flex;
      height: 150px;
      justify-content: center;
      
  }
  .share:hover {
      cursor: pointer; 
  }
  .share{
      margin-top: 25px;
  }
</style>
