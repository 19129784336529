<template>
    <div id="home">
        <nav-bar class="home-nav">
            <template v-slot:center>园区推荐</template>
        </nav-bar>
    </div>
    <van-list v-model:loading="loading" :finished="finished" id="noMore" finished-text="没有更多了" @load="onLoad">
        <my-goods-list :list=list></my-goods-list>
    </van-list>
    <!-- <tab-bar></tab-bar>
    <router-view></router-view> -->
</template>
<script >
import NavBar from '../../components/common/navbar/NavBar.vue'
import MyGoodsList from './goodslist/Goodslist.vue'
import { getrecommendation } from '../../network/index'
// import TabBar from '../../components/content/tabbar/tabbar.vue'
import {ref} from 'vue'
export default {
    name: "MyHome",
     setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const whj_toPage = ref(1);
    const onLoad = () => {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      getrecommendation(whj_toPage.value).then(res=> {
          const result = res.list
          whj_toPage.value++;
           list.value.push(...result)
          loading.value = false;
          //当数据全部加载完成，才会触发finished.value = true，为true
          if(result.length > 0) {

          }else {
              finished.value = true
          }
      })
    };

    return {
      list,
      onLoad,
      loading,
      finished,
    };
  },
    components: {
        NavBar,
        MyGoodsList,
                                      
    },
}
</script>

<style scoped>
    .home-nav {
        background-color: red;
        color: #ffff;
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
        z-index: 999;
        padding-top: 4px;
    }
    #home{
        padding-top: 44px;
    }
    #noMore{
        margin-bottom: 60px
    }
   
</style>