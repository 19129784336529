<template>
    <div class="category" >
       <nav-bar class="morecate">
                <template v-slot:center>更多园区</template>
       </nav-bar>
       <!-- 下拉 -->
       <div class="selecttop">
           <van-dropdown-menu>
                <van-dropdown-item v-model="value1" :options="option1"  @change="changeValue(option1[value1])"/>
                <van-dropdown-item />
            </van-dropdown-menu>
       </div>
       <!-- 搜索 -->
       <div class="search">
           <van-search
                v-model="value"
                show-action
                label="城市"
                placeholder="请输入搜索关键词">
                <template #action>
                    <div @click="onClickButton">搜索</div>
                </template>
            </van-search>
       </div>
       <van-list
            v-model:loading="loading"
            class="vant_list"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad">
             <cate-item :list='list'></cate-item>
        </van-list>
    </div>
</template>
<script >
import NavBar from '../../components/common/navbar/NavBar.vue'
import CateItem from '../category/cateItem/CateItem.vue'
import {getmorehourse,getSearch,getsorthouses,getsorthousesByHousesId} from '../../network/index'
import { Toast } from 'vant'
import { ref } from 'vue';
export default {
    setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const whj_toPage = ref(1);
    const onLoad = () => {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      //1、发起网络请求
        getmorehourse(whj_toPage.value).then(res=>{
             whj_toPage.value+=1;
             const result = res.list
             //2、将数据存储到list中(必须使用.value)
             list.value.push(...result)
             //3、将loading设置为false
             loading.value = false;
             //4、判断list长度是否大于0
             if(result.length > 0) {
                //  alert("还有数据");
                //  // whj_toPage=2
                // //  pageN.value = res.whj_toPage
                // alert(whj_toPage.value)
             }else {
                finished.value = true; 
             }
        })
    };
   
    //下拉更多
    const value1 = ref();
    const value2 = ref('a');
    const option1 = [
    //   { text: '全部商品', value: 0 },
    //   { text: '新款商品', value: 1 },
    //   { text: '活动商品', value: 2 },
    ];
    const option2 = [
      { text: '默认排序', value: 'a' },
      { text: '好评排序', value: 'b' },
      { text: '销量排序', value: 'c' },
    ];

     getsorthouses().then(res=> {
       var result = res.map(o=>{return{text: o.classify,id:o.id}});
        for(let i = 0; i<result.length; i++) {
            result[i].value = i;
        }
        value1.value=0
        console.log(option1)
        option1.push(...result)
    })


    function changeValue(classifyId) {
        getsorthousesByHousesId(classifyId.id).then(res=> {
           const result = res.list
             //2、将数据存储到list中(必须使用.value)
             list.value.push(...result)
              //3、将loading设置为false
             loading.value = false;
             //4、判断list长度是否大于0
             if(result.length > 0) {
                //  // whj_toPage=2
                // //  pageN.value = res.whj_toPage
                // alert(whj_toPage.value)
             }else {
                finished.value = true;
             }
                console.log(res)
        })
         list.value = []
    }
    // 搜索
    const value = ref('');
    function onClickButton() { 
        //发起请求
        getSearch(value.value).then(res=> {
             const result = res.list
             //2、将数据存储到list中(必须使用.value)
             list.value.push(...result)
              //3、将loading设置为false
             loading.value = false;
             //4、判断list长度是否大于0
             if(result.length > 0) {
                //  // whj_toPage=2
                // //  pageN.value = res.whj_toPage
                // alert(whj_toPage.value)
             }else {
                finished.value = true;
             }
                console.log(res)
        })
        list.value = []
    }
    return {
        list,
        onLoad,
        loading,
        finished,
        value,
        onClickButton,
        value1,
        value2,
        option1,
        option2,
        changeValue
    };
  },
    name: "MyCategory",
    data() {
        return {
          
        }
    },
     created() {
      
    },
    components: {
        NavBar,
        CateItem,
    },
    methods: {
       
    }   
}
</script>

<style scoped>
    .selecttop {
        color: #ffff;
        position: fixed;
        z-index: 999;
        position: flexd;
        left: 0px;
        right: 0px;
    }
    .category {
        padding-top: 44px;
        margin-bottom: 60px;
    }
    .morecate {
       background-color: red;
        color: #ffff;
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
        z-index: 999;
        padding-top: 4px;
    }
    .search {
        color: #ffff;
        position: fixed;
        top: 91px;
        left: 0px;
        right: 0px;
    }
    .vant_list {
        margin-top: 113px;
    }
</style>

