<template>
  <div>
    <vant-nav-bar-enlist></vant-nav-bar-enlist>
     <div class="login">  
        <form method="post" style="margin-bottom: 22px;">  
            <span>
              <van-icon class="uname" name="manager" size="40"/><input type="text" class="inputusername"  placeholder="姓名" v-model="uname" name='username' style="margin-left:17px"/>
            </span><br />
          <span>
            <van-icon class="passw" name="phone" size="40" /><input type="text" class="inputpassword"  placeholder="电话号码" v-model="phone" name='passowrd' style="margin-left:16px" />
          </span>
         </form> 
         <!-- 意向楼盘 -->
          <!-- <p>1、意向楼盘：{{location.Info}}</p> -->
          <!-- 意向楼层 -->
          <!-- 单选框近期线下看房时间：0、一周内：1、一月半：2、半年内 -->
          <!-- 2、近来线下看房时间<br />
         &nbsp;&nbsp; 一周内: <input type="radio" name="timer" value="1" style="color: red"/><br /> 
         &nbsp;&nbsp; 一月半: <input type="radio" name="timer" value="2" /><br />
         &nbsp;&nbsp; 半年内: <input type="radio" name="timer" value="3" /><br /> -->
           <!-- 是否是上海户口或在海南交满3年个税,0、代表是，1、代表不是 -->
        <!-- 3、是否是海南户口或在海南交满3年个税<br />
        &nbsp;&nbsp; 是: <input type="radio" name="hainan" value="0"  /><br />
        &nbsp;&nbsp; 否: <input type="radio" name="hainan" value="1" /><br /> -->
        <!-- 4、意向楼层： &nbsp;&nbsp;<input ref="floor" type="text" value="" style="width: 145px; margin-left: 37px;"><br /> -->
          咨询问题：<br /> &nbsp;&nbsp;<textarea ref="floor" role="30" cols="30" type="text" value="" style="width: 234px; margin-left: 37px; height: 152px;"></textarea><br />
        <!-- <p></p>
        5、意向面积(平方)： &nbsp;&nbsp;<input ref="area" type="text" style="width: 146px;">
        <p></p>
        6、总预算(万元)：<input type="text" ref="allmoney" style="width: 145px; margin-left: 26px;"><br /><br /> -->
      </div>
      <div class="yuyue" @click="Isshow()">
            <span style="background-color: #ececec;">业务咨询添加</span>
      </div>



      <!-- <van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
          <div id="btn" ref="btns" :data-clipboard-text="copyInfo">
          </div>
           <van-popup v-model:show="show">内容已经已复制</van-popup>


        <!-- 预约时间看房 -->
      <div  ref="Ishow" style="display: none;">
          <div ref="update">
            <van-datetime-picker
            v-model="currentDate"
            @cancel="cancel()"
            @confirm="confirm()"
            type="datetime"
            title="业务咨询添加"
            :min-date="minDate"
            :max-date="maxDate" />
        </div>
      </div>
        <div style="margin: 43px; margin-top: 69px;">
         <van-button round block type="primary" native-type="submit" @click="submit"> 提交</van-button>
        </div>

  </div>
</template>

<script>
import VantNavBarEnlist from '../vant_navbar/VantNavBarEnlist.vue'
import {getCateappy,getCateDetail} from '../../../../network/index'
import { ref,getCurrentInstance,onMounted, reactive} from 'vue';
import { DatetimePicker,Dialog} from 'vant';
import $ from 'jquery'
import {useRouter} from 'vue-router'
import ClipboardJS from 'clipboard'
//获取当前时间
  export default {
     setup() {
       //复制的内容
        const copyInfo = ref("")
      //弹框默认是false
       const show = ref(false);
      //当前时间
        const currentDate = ref("");
        const router =  useRouter();
        // 当前登录的id
        const userId =  router.currentRoute.value.query.userId
        //楼层位置
        const location = reactive({});
        //获取实例
         const  getInstance  = getCurrentInstance()
         //楼盘的hourseId
        const housesId = window.localStorage.getItem("hourseid")
        //发起网络请求：通过楼层Id,查询出当前的楼层
        getCateDetail(housesId).then(res=> {
          location.Info = res.location
        })
        // 点击事件
        const uname = ref('')
        const phone = ref('')
        const proxy = reactive({})
       onMounted (() => {
        // 定义组件实例
        proxy.Info = getCurrentInstance()
	    })
       function submit() {
        //  var re = /^1\d{10}$/
          var re = /^[1][3,4,5,7,8,9][0-9]{9}$/;
          if(uname.value =='' || phone.value == '') {
            alert("请输入正确的用户信息")
          }else if(!re.test(phone.value)){
            alert("手机格式错误")     
          }else {
          //2、单选框近期线下看房时间
          var see = $("input[name='timer']:checked").val();   //undefined
          // //3、是否是上海户口或在海南交满3年个税
          // var state = $("input[name='hainan']:checked").val();   //undefined
          // //4、意向楼层：
          //  var intention = proxy.Info.refs.floor.value
          // //5、意向面积
          //  var area = proxy.Info.refs.area.value
          // //6、总预算
          //  var budget = proxy.Info.refs.allmoney.value
          // //7、意向楼盘
          //  var sold = location.Info
           //咨询问题
           var intention = proxy.Info.refs.floor.value

            function btn(val) {
            var clipboard = new ClipboardJS('#btn');
            copyInfo.value = val
            clipboard.on('success', function(e) {
                console.info('Action:', e.action);
                console.info('Text:', e.text);
                console.info('Trigger:', e.trigger);
                console.log(e)
                show.value = true;
            });

            clipboard.on('error', function(e) {
                console.error('Action:', e.action);
                console.error('Trigger:', e.trigger);
                console.log(e)
            });
            
          }


          getCateappy({"uname":uname.value,
                  "phone":phone.value,
                  "housesId":housesId,
                  "userId":userId,
                  "intention":intention,
                  }).then(res=> {
                     console.log(res)
                      if(res.status == '200') {
                            // currentPageUrl.value = res.Info
                            Dialog.confirm({
                                title: '标题',
                                message:
                                res.Info,
                              }).then(() => {
                                    btn(res.Info)
                                    setTimeout(()=>{
                                      proxy.Info.refs.btns.click()
                                      //重定向到详情页面
                                      router.push({path: `/catedetail/${housesId}`,query: {userId: userId}})
                                    },1000)
                               
                              })
                              .catch(() => {  
                              });                            
                      }

           })
        }
              }

        //取消
        function cancel(){
          proxy.Info.refs.Ishow.style.display="none"     
        }
      //确定
      function confirm() {
         proxy.Info.refs.Ishow.style.display="none"
      }
      //控制显示和隐藏
      function Isshow() {
        proxy.Info.refs.Ishow.style.display="none"
      }
 
      return {
          minDate: new Date(2020, 0, 1),
          maxDate: new Date(2025, 10, 1),
          currentDate,location,
          userId,housesId,submit,cancel,confirm,uname,phone,onMounted,Isshow,
          copyInfo,show
          // currentPageUrl
      }
    },
    name:'myEnlist',
    components: {
        VantNavBarEnlist
    },
    watch: {}
  }

</script>
<style scoped>
 .hicopy{
  display: none;
}
  .yuyue {
     display: none;
      margin-bottom: 24px;
      margin-top: 17px;
      margin-left: 56px;
      width: 99px;
      cursor: pointer;
  }
  .yuyue span {
      padding: 6px;
      border: 1px solid;
  }
 .uname,.passw {
    position: relative;
    font-size: 40px;
    top: 9px;
    left: 8px;
}
    .login {
    margin: 0px auto;
    width: 300px;
    margin-top: 40px;
  }
  .inputusername,.inputpassword{   
    width: 224px;
    height: 45px;
    margin-bottom: 10px;
    outline: none;
    padding: 10px;
    font-size: 13px;
    color: #111;
    border-top: 1px solid #312E3D;
    border-left: 1px solid #312E3D;
    border-right: 1px solid #312E3D;
    border-bottom: 1px solid #56536A;
    border-radius: 4px;
    background-color: #f6f2f257;
  }   
  .but{   
    width: 202px;
    min-height: 20px;
    display: block;
    background-color: #4a77d4;
    border: 1px solid #3762bc;
    color: #fff;
    padding: 9px 14px;
    font-size: 15px;
    line-height: normal;
    border-radius: 5px;
    /* margin: 0; */
    margin-left: 35px;
}   
</style>